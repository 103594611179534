// YouTube Videos automatisch in der Lightbox öffnen
function openVideoInLightBox(event) {
    event.preventDefault();
    jQuery(this)
        .magnificPopup({
            type: "iframe",
            iframe: {
                patterns: {
                    youtube: {
                        index: "youtube.com/",
                        id: "v=",
                        src: "https://www.youtube-nocookie.com/embed/%id%?autoplay=1&rel=0&rel=0"
                    }
                }
            }
        })
        .magnificPopup("open");
}

jQuery('body').on('click tap', '.videos-in-lightbox a[href*=youtube]', openVideoInLightBox);
