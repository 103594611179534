const sliderWrapper = document.querySelector('.ipr-slider');

if( sliderWrapper != undefined ) {

  const arrowPrev = sliderWrapper.querySelector('.ipr-arrow-prev');
  const arrowNext = sliderWrapper.querySelector('.ipr-arrow-next');

  const slider = sliderWrapper.querySelector('.ipr-section-employee-slider');
  const slides = sliderWrapper.querySelectorAll('.ipr-employee-slide');

  var clone = slides[slides.length - 1].cloneNode(true);
  slider.prepend(clone);
  var clone = slides[slides.length - 2].cloneNode(true);
  slider.prepend(clone);
  var clone = slides[0].cloneNode(true);
  slider.appendChild(clone);
  var clone = slides[1].cloneNode(true);
  slider.appendChild(clone);

  slides[0].classList.add('ipr-active');
  slider.style.transform = 'translateX(-12.5vw)';

  index = -1;

  arrowPrev.addEventListener('click', function() {
    var employeesWidth = sliderWrapper.querySelector('.ipr-section-employee-slider').clientWidth / sliderWrapper.querySelector('.ipr-section-employee-slider').childElementCount;
    var clientWidth = sliderWrapper.clientWidth;
    var aspect = clientWidth / employeesWidth;
    var employeeWidth = 100 / aspect;
    var halfEmployeeWidth = employeeWidth / 2;
    var oldActive = sliderWrapper.querySelector('.ipr-active');
    var newActive = oldActive.previousElementSibling;
    oldActive.classList.remove('ipr-active');
    index++;
    if( index == 0 ) {
      newActive = slides[slides.length - 1 ];
      index = -slides.length - 1 ;
      var transform = halfEmployeeWidth + employeeWidth * index;
      slider.style.transform = 'translateX(' + transform + 'vw)';
      index++;
    }
    newActive.classList.add('ipr-active');
    var transform = halfEmployeeWidth + employeeWidth * index;
    slider.style.transform = 'translateX(' + transform + 'vw)';
  })

  arrowNext.addEventListener('click', function() {
    var employeesWidth = sliderWrapper.querySelector('.ipr-section-employee-slider').clientWidth / sliderWrapper.querySelector('.ipr-section-employee-slider').childElementCount;
    var clientWidth = sliderWrapper.clientWidth;
    var aspect = clientWidth / employeesWidth;
    var employeeWidth = 100 / aspect;
    var halfEmployeeWidth = employeeWidth / 2;
    var oldActive = sliderWrapper.querySelector('.ipr-active');
    var newActive = oldActive.nextElementSibling;
    oldActive.classList.remove('ipr-active');
    index--;
    if( index < -slides.length ) {
      newActive = slides[0]
      index = 0;
      var transform = halfEmployeeWidth + employeeWidth * index;
      slider.style.transform = 'translateX(' + transform + 'vw)';
      index--;
    }
    newActive.classList.add('ipr-active');
    var transform = halfEmployeeWidth + employeeWidth * index;
    slider.style.transform = 'translateX(' + transform + 'vw)';
  })

}
