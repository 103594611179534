// Google Analytics Tracking Code
// import './components/google-analytics';

// Matomo Tracking Code
// import './components/matomo';

// Videos in Lightbox anzeigen
import './components/video-lightbox';

// Import Components
import './components/scrollTrigger';
import './components/slider';

const menuIcon = document.querySelector('.ipr-menu-icon');
menuIcon.addEventListener("click", function() { 
  const menu = document.querySelector('.ipr-menu-wrapper');
  menu.classList.toggle('open');
});
