gsap.registerPlugin(ScrollTrigger);

const sections = gsap.utils.toArray('.ipr-section');
sections.forEach((section) => {
  let primaryLayer = section.querySelector('.ipr-section-content')
  let secondaryLayer = section.querySelector('.ipr-section-transition')
  
  gsap.to(primaryLayer, {
    clipPath: 'circle(200% at 50% 100%)',
    scrollTrigger: {
      trigger: section,
      start: '-200px bottom',
      end: '+=1000px',
      scrub: true,
    }
  });

  gsap.to(secondaryLayer, {
    clipPath: 'circle(200% at 50% 100%)',
    scrollTrigger: {
      trigger: section,
      start: '-300px bottom',
      end: '+=1000px',
      scrub: true,
    }
  });
});

// VIDEO FULLSCREEN PLAY
const videoSections = gsap.utils.toArray('.ipr-full-video');
videoSections.forEach((videoSection) => {
  let sectionVideo = videoSection.querySelector('video');

  if(sectionVideo != null) {

    videoScrub(sectionVideo, {
    scrollTrigger: {
      trigger: videoSection,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
      markers: true,
      }
    });
    function videoScrub(video, vars) {
      let once = (el, event, fn) => {
        let onceFn = function () {
          el.removeEventListener(event, onceFn);
          fn.apply(this, arguments);
        };
        el.addEventListener(event, onceFn);
        return onceFn;
      },
      prepFunc = () => { video.play(); video.pause(); },
      prep = () => once(document.documentElement, "touchstart", prepFunc),
      src = video.currentSrc || video.src,
      tween = gsap.fromTo(video, {currentTime: 0}, {paused: true, immediateRender: false, currentTime: video.duration || 1, ease: "none", ...vars}),
      resetTime = () => (tween.vars.currentTime = video.duration || 1) && tween.invalidate();
      prep();
      video.readyState ? resetTime() : once(video, "loadedmetadata", resetTime);
      return tween;
    }
  }
});

const scrollVideoSections = gsap.utils.toArray('.ipr-default');
scrollVideoSections.forEach((scrollVideoSection) => {
  var canvas = scrollVideoSection.querySelector("canvas");
  const context = canvas.getContext("2d");

  var imgPath = canvas.style.backgroundImage.split('/');
  
  canvas.width = 1000;
  canvas.height = 1000;
  
  const frameCount = 80;
  const currentFrame = (index) =>
    `/wp-content/uploads/${imgPath[3]}/${index.toString().padStart(4, '0')}.jpg`;
  
  const images = [];
  const video = {
    frame: 0
  };
  
  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }
  
  gsap.to(video, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    scrollTrigger: {
      trigger: scrollVideoSection,
      start: "top bottom",
      end: "bottom bottom",
      scrub: true,
    },
    onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
  });
  
  images[0].onload = render;
  
  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(images[video.frame], 0, 0);
  }
  
});



